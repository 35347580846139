import { t } from '../languages';
// import { URIPrivacyPolicy, URIUserAgreement } from "constants/uri";
import Redirect from "page/Redirect";
import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import H5Routes from "./H5Routes";
import { isOverseas } from "lib/utils";

const Home = lazy(() => import(/* webpackChunkName: "home" */ "page/Home"));
const HomeEn = lazy(() => import(/* webpackChunkName: "home" */ "page/HomeEn"));
const Download = lazy(() => import(/* webpackChunkName: "home" */ "page/Download"));
const AppleSignin = lazy(() => import(/* webpackChunkName: "home" */ "page/AppleSignin"));

const ThirdPartySDK = lazy(() => import(/* webpackChunkName: "doc" */ "page/ThirdPartySDK"));
const Article = lazy(() => import(/* webpackChunkName: "article" */ "page/Article"));
const HomeDoc = lazy(() => import(/* webpackChunkName: "homedoc" */ "page/Home/components/HomeDoc/HomeDoc"));

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Suspense fallback={<div />} children={isOverseas() ? <HomeEn /> : <Home />} />,
    children: [],
  },
  // 第三方用户协议
  {
    path: "third-party-sdk",
    element: <ThirdPartySDK />,
  },
  // 下载链接
  { path: "/download", element: <Download /> },

  // 文档协议文章页面
  { path: "/article", element: <Article /> },

  // 文章页面
  { path: "/doc", element: <HomeDoc /> },

  H5Routes,

  { path: "/redirect", element: <Redirect /> },

  { path: "/signinwithapple", element: <AppleSignin /> }
  // {
  //   path: "/test",
  //   element: <Test />,
  // },
]);
